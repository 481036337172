import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { FormAndMap } from '../components/FormAndMap'
import { CTA } from '../components/CTA'
import { SingleProcedure } from '../components/SingleProcedure'
import { TeamGrid } from '../components/TeamGrid'
import { Title } from '../components/Title'
import { Styled } from '../styles/servicos.styles'
import { graphql } from 'gatsby'


import Slider from "react-slick";

const HarmonizacaoFacialPage = ({
  data: { harmonizacaoJson: content, miscJson: misc }, location
}) => {

  const teamMember = misc.teamMembers.find(
    (member) => member.specialty.includes('harmonizacao-facial')
  )

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: 'vertical-dots',
    autoplay: true,
  };
  const [isDesktop, setDesktop] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1000)
    }
  }, [])


  return (
    <Layout isHomePage location={location}>
      <SEO title="Harmonização Facial" description={content.seoDescription} />
      <Styled.Container>

        <section className="bg-gold">
          <Slider {...settings}>
            {content.slide.map((item, index) => (
              <div className='container-slide'>
                <h3 >{item.title}</h3>
                <h2>{item.subtitle}</h2>
                <p className='description'>{item.description}</p>
                <CTA
                  text={item.btn}
                  url={item.btnLink}
                  small
                  className="desktop"
                  color="gold"
                />
                <img src={isDesktop ? item.image : item.imagemobile} className="object-fit full-width" style={{ height: "100vh" }} />
              </div>
            ))}
          </Slider>
        </section>

        <section className="content bg-gold p-top">
          <div
            className="rounded-section"
            style={{ backgroundImage: `url(${content.backgroundImg})` }}
          >
            <div id="about" className="about flex-center">
              <div className="about__video">
                <iframe
                  src={content.aboutVideo}
                  title={content.aboutTitle}
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  frameBorder="0"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
                />
              </div>
              <div className="about__content">
                <Title text={content.aboutTitle} />
              </div>
            </div>
            <div className="height-auto pb-5">
              <section className='separator'>
                <div className='container-hr'>
                  <hr />
                </div>
                <div>
                  <Title text={content.separatorTitle} className="gold" />
                </div>
                <div className='container-hr'>
                  <hr />
                </div>
              </section>

              <section className="services">
                {content.procedures.map((procedure) => (
                  <SingleProcedure
                    key={procedure.id}
                    id={procedure.id}
                    title={procedure.title}
                    text={procedure.text}
                    image={isDesktop ? procedure.img : procedure.imgMobile}
                    btn={procedure.btn}
                    btnLink={procedure.btnLink}
                    onClick={procedure.btnLink}
                    imgposition={procedure.imgPosition}
                  />
                ))}
              </section>

              <section className='separator'>
                <div className='container-hr'>
                  <hr />
                </div>
                <div>
                  <Title text={content.teamTitle} className="gold" />
                </div>
                <div className='container-hr'>
                  <hr />
                </div>
              </section>

              <section className="team harmonizacao">
                {teamMember && (
                  <>
                    <TeamGrid

                      members={misc.teamMembers.filter(
                        (member) => member.specialty.includes('harmonizacao-facial')
                      )}
                    />
                  </>
                )}
              </section>
            </div>
          </div>

        </section>
        <div id="marcacao">
          <FormAndMap />
        </div>
      </Styled.Container>


    </Layout>
  )
}

export default HarmonizacaoFacialPage

export const pageQuery = graphql`
  query {
    harmonizacaoJson {
      seoDescription
      slide{
        image
        imagemobile
        title
        subtitle
        description
        btn
        btnLink
      }
      slides {
        title
        text
        img
        video
        disabled
      }
      aboutTitle
      aboutText
      aboutVideo
      introBtn
      introBtnUrl
      backgroundImg
      separatorTitle
      procedures {
        id
        title
        text
        img
        imgMobile
        imgPosition
        slides
        video
        btn
        btnLink
      }
      teamTitle
      cta
      ctaUrl
    }
    miscJson {
      teamMembers {
        name
        role
        omd
        text
        img
        specialty
        areas{
          btn
          btnLink
        }
      }
    }
  }
`
